import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageContent from '../components/UI/PageContents';

const AboutUs = () => (
  <Layout headerText="About us">
    <SEO title="About us" />
    <PageContent>
      <p>
        We, <b>Perfect Day Events</b> located at Kadavanthara in Ernakulam,
        Kerala, operates in the field of Event planing, entertainment, party
        planning, video and photographing and more.
      </p>
      <p>
        We have successfully conceptualized and completed the event designs and
        executed numerous corporate, commercial events. We have completed some
        of the finest events with the help of our renowned crew. We provide
        services that surpass our clients’ expectations. To accomplish this, our
        responsible crew hire and retain first class experienced workforce by
        approaching the event venue, plan, organize and execute events with
        dedication, hard work &amp; honesty.
      </p>
      <p>
        Along with that, we also have had a passion for building fantastic
        client relationship for over years, so we know what is important – high
        quality planning, skilled execution with precision which make our
        clients day a{' '}
        <b>
          <i>Perfect Day</i>
        </b>
        . We carefully check every stage in event planning till the end of the
        event.
      </p>
    </PageContent>
  </Layout>
);

export default AboutUs;
